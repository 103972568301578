<template>
  <div
    @dragover="handleDragOver"
    @drop="handleDrop"
    @dragleave="handleDragLeave"
    @dragenter="handleDragEnter"
    class="main-content"
  >
    <v-toolbar flat color="orange darken-3">
      <v-toolbar-title> Upload {{ view_type }} File</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        title="Click to close"
        @click="$emit('close-upload-file-dialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <div v-if="error" class="ma-4">
      <v-alert
        v-for="(item, index) in errors"
        type="error"
        :key="index"
        dismissible
        outlined
      >
        {{ item.message }}
      </v-alert>
    </div>

    <v-row class="mt-2 mx-2" justify="center">
      <v-col>
        <p class="d-flex align-start orange--text darken-3">
          <v-icon class="mr-4 orange--text darken-3">
            mdi-information-variant-circle-outline
          </v-icon>

          You can Drag and drop files here, or click the "Select Files" button
          to choose files from your computer. You can upload multiple files at
          once. Click on the file name to edit the file details and then click
          "Save" to save the changes. If you want to view the file you can click
          on the "Preview" button, and click the "Upload Files" button to upload
          all saved files.
        </p>
      </v-col>
    </v-row>

    <v-tabs v-show="!isDragging && !uploading && !uploadCompleted" vertical>
      <v-tab
        v-for="(item, index) in droppedFiles"
        :key="index"
        @click="isSplit = false"
      >
        <v-icon v-if="item.saved" color="success" left> mdi-check </v-icon>

        <v-icon left>
          {{ getIcon(item.type) }}
        </v-icon>
        {{ getFileName(item.name) }}
      </v-tab>

      <v-tab-item
        v-for="(item, index) in droppedFiles"
        :key="index"
        align-with-title
      >
        <div>
          <div class="split-container" v-if="isSplit">
            <!-- column 1 -->
            <div class="column" style="max-width: 50%">
              <v-form ref="splitForm">
                <v-card flat>
                  <v-card-text class="text-subtext-1">
                    <v-row>
                      <v-col cols="12" class="d-flex justify-end">
                        <p class="ma-0 text-body-1 teal--text">
                          {{ index + 1 }} / {{ `${droppedFiles.length}` }} Files
                        </p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="item.file_cat"
                          :rules="[rule.required]"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          label="Category"
                          clearable
                          dense
                          @change="handleFileCategoryChange(item)"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="item.file_type"
                          :rules="[rule.required]"
                          :items="filteredFileTypes"
                          item-text="name"
                          item-value="id"
                          label="File Type"
                          clearable
                          dense
                          @change="handleFileTypeChange(item)"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <p>{{ item.proto_desc }}</p>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="item.name"
                          :rules="[rule.required]"
                          label="File Name"
                          dense
                          @input="item.saved = false"
                        />
                        <span class="grey--text">Size :</span> {{ item.size }}
                        <span class="grey--text ml-3">Type :</span>
                        {{ item.type }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="item.description"
                          label="Description"
                          rows="1"
                          auto-grow
                          dense
                          hide-details
                          outlined
                          @input="item.saved = false"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider class="my-4" />

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-select
                          v-model="item.status"
                          :items="status_options"
                          :rules="[rule.required]"
                          label="Status"
                          clearable
                          dense
                          @change="item.saved = false"
                        />
                      </v-col>

                      <v-col cols="12" sm="8" v-if="item.status === 'Archived'">
                        <v-textarea
                          v-model="item.archived_reason"
                          label="Archived Reason"
                          rows="1"
                          auto-grow
                          dense
                          outlined
                          @input="item.saved = false"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="5">
                        <v-checkbox
                          v-model="item.signature_requirement"
                          label="Signature Requirement"
                          dense
                          hide-details
                          @change="item.saved = false"
                        />
                      </v-col>

                      <v-col cols="12" sm="7" v-if="item.signature_requirement">
                        <v-select
                          v-model="item.signature_status"
                          :items="signature_status_options"
                          :rules="[rule.required]"
                          label="Signature Status"
                          clearable
                          dense
                          @change="item.saved = false"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text>
                    <p>Associations:</p>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="item.association.customers"
                          :items="customerNames"
                          :return-object="true"
                          item-text="name"
                          label="Customers"
                          chips
                          deletable-chips
                          small-chips
                          dense
                          hide-details
                          multiple
                          @change="item.saved = false"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="item.association.properties"
                          :items="propertyAddresses"
                          :return-object="true"
                          item-text="address"
                          label="Properties"
                          chips
                          deletable-chips
                          small-chips
                          dense
                          hide-details
                          multiple
                          @change="item.saved = false"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text>
                    Visibility:

                    <v-row>
                      <v-col cols="12" sm="3">
                        <v-checkbox
                          v-model="item.view_company"
                          label="Company"
                          dense
                          hide-details
                          @change="item.saved = false"
                        />
                      </v-col>

                      <v-col cols="12" sm="3">
                        <v-checkbox
                          v-model="item.view_customer"
                          label="Customer"
                          dense
                          hide-details
                          @change="item.saved = false"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="item.provided_by"
                          :items="provided_by_options"
                          :rules="[rule.required]"
                          label="Provided By"
                          clearable
                          dense
                          @change="item.saved = false"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider class="my-4" />

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <!-- note list -->
                        <v-card
                          style="background: transparent"
                          ref="file_note_list"
                          flat
                        >
                          <p class="text-h6 font-weight-medium">
                            Notes
                            <v-icon> mdi-note-edit-outline </v-icon>
                          </p>
                          <p class="mb-0 grey--text font-weight-medium">
                            Click on the text field to input your notes, you can
                            also add screenshots or image files by copy/paste or
                            drag and drop into the text field below.
                          </p>

                          <v-card-text>
                            <p
                              v-if="item.notes.length === 0"
                              class="text-center grey--text text-subtitle-1"
                            >
                              No notes available. Make a note below.
                            </p>

                            <div
                              v-for="(note, index) in item.notes"
                              :key="note.id"
                              class="my-2"
                            >
                              <v-card class="rounded-lg">
                                <v-card-text>
                                  <p class="d-flex align-center mb-0">
                                    <span>
                                      <v-icon class="text-body-1 mr-1">
                                        mdi-account-outline
                                      </v-icon>
                                      {{
                                        note.user ? note.user.first_name : ""
                                      }}
                                      {{ note.user ? note.user.last_name : "" }}
                                    </span>

                                    <span class="ml-auto">
                                      <v-icon class="text-body-1 mr-1">
                                        mdi-calendar-outline
                                      </v-icon>
                                      {{ note.createdAt | formatDate }}
                                    </span>

                                    <span class="ml-5">
                                      <v-btn
                                        title="Click to edit note"
                                        @click="handle_edit_note(note, index)"
                                        icon
                                        small
                                      >
                                        <v-icon
                                          color="teal"
                                          class="text-body-1"
                                        >
                                          mdi-pencil-outline
                                        </v-icon>
                                      </v-btn>

                                      <v-btn
                                        title="Click to delete"
                                        @click="handle_delete_note(index)"
                                        icon
                                        small
                                      >
                                        <v-icon color="red"> mdi-close </v-icon>
                                      </v-btn>
                                    </span>
                                  </p>
                                </v-card-text>

                                <v-divider class="my-4" />

                                <v-card-text>
                                  <div
                                    v-if="note.note"
                                    style="white-space: pre"
                                  >
                                    {{ note.note }}
                                  </div>

                                  <div
                                    v-if="note.content"
                                    class="file-note-contents"
                                    v-html="note.content"
                                  ></div>
                                </v-card-text>
                              </v-card>
                            </div>
                          </v-card-text>
                        </v-card>

                        <!-- note editor -->
                        <v-card
                          style="background: transparent"
                          ref="task_note_editor"
                          flat
                        >
                          <v-card-text class="pa-1">
                            <div>
                              <RichTextEditor
                                ref="fileTipTapEditor"
                                type="file_note"
                                :value_to_edit="temp_note"
                                @handleSaveNote="
                                  temp_note_index = index;
                                  handle_save_note($event);
                                "
                                @handleCancelEdit="
                                  temp_note = undefined;
                                  temp_note_index = null;
                                "
                              />
                            </div>
                          </v-card-text>
                        </v-card>

                        <v-dialog v-model="note_dialog" fullscreen>
                          <v-toolbar
                            class="sticky-toolbar orange darken-3"
                            flat
                          >
                            <v-toolbar-title>
                              <v-icon>mdi-image</v-icon> Image Preview
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              @click="note_dialog = false"
                              title="Close"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <v-card>
                            <v-card-text>
                              <v-img :src="selectedImage" aspect-ratio="1" />
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions
                    class="grey darken-4"
                    style="position: sticky; bottom: 0"
                  >
                    <v-btn
                      text
                      color="teal"
                      :disabled="item.saved"
                      @click="handleSaveFile(item, index)"
                      title="Click to save"
                    >
                      <v-icon class="mr-2 text-body-1">
                        mdi-content-save-outline
                      </v-icon>
                      Save
                    </v-btn>

                    <v-btn
                      text
                      color="red"
                      @click="deleteFile(index)"
                      title="Click to delete"
                    >
                      <v-icon class="mr-2 text-body-1">
                        mdi-trash-can-outline
                      </v-icon>
                      Delete
                    </v-btn>

                    <v-btn
                      text
                      @click="previewFile(item.type, item.dataUrl)"
                      color="grey"
                      :title="
                        isSplit ? 'Click to close preview' : 'Click to preview'
                      "
                    >
                      <v-icon v-if="isSplit" class="mr-2">mdi-eye-off</v-icon>
                      <v-icon v-else class="mr-2"> mdi-eye </v-icon>
                      Preview
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </div>

            <!-- column 2 -->
            <div
              :class="
                isImageFile(previewFileData) || isPdfFile(previewFileData)
                  ? 'column px-2'
                  : 'column px-2 d-flex align-center justify-center grey darken-4'
              "
              style="max-width: 50%; overflow: hidden"
            >
              <img
                v-if="isImageFile(previewFileData)"
                :src="previewFileData.dataUrl"
                alt="Image preview"
                style="max-width: 100%; max-height: 100%; object-fit: contain"
                draggable="false"
              />

              <embed
                v-else-if="isPdfFile(previewFileData)"
                :src="previewFileData.dataUrl"
                type="application/pdf"
                width="100%"
                height="100%"
              />

              <div v-else class="grey--text">
                Previewing this type of file is not available.
              </div>
            </div>
          </div>

          <div class="non-split-container" v-else>
            <v-form ref="notSplitForm">
              <v-card flat>
                <v-card-text class="text-subtext-1">
                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <p class="ma-0 text-body-1 teal--text">
                        {{ index + 1 }} / {{ `${droppedFiles.length}` }} Files
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="item.file_cat"
                        :rules="[rule.required]"
                        :items="categories"
                        item-text="name"
                        item-value="id"
                        label="Category"
                        clearable
                        dense
                        @change="handleFileCategoryChange(item)"
                      />
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="item.file_type"
                        :rules="[rule.required]"
                        :items="filteredFileTypes"
                        item-text="name"
                        item-value="id"
                        label="File Type"
                        clearable
                        dense
                        @change="handleFileTypeChange(item)"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="8">
                      <p>{{ item.proto_desc }}</p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        v-model="item.name"
                        :rules="[rule.required]"
                        label="File Name"
                        dense
                        @input="item.saved = false"
                      />
                      <span class="grey--text">Size :</span> {{ item.size }}
                      <span class="grey--text ml-3">Type :</span>
                      {{ item.type }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-textarea
                        v-model="item.description"
                        label="Description"
                        rows="1"
                        auto-grow
                        dense
                        hide-details
                        outlined
                        @input="item.saved = false"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider class="my-4" />

                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-select
                        v-model="item.status"
                        :items="status_options"
                        :rules="[rule.required]"
                        label="Status"
                        clearable
                        dense
                        @change="item.saved = false"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" v-if="item.status === 'Archived'">
                      <v-textarea
                        v-model="item.archived_reason"
                        label="Archived Reason"
                        rows="1"
                        auto-grow
                        dense
                        outlined
                        @input="item.saved = false"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-checkbox
                        v-model="item.signature_requirement"
                        label="Signature Requirement"
                        dense
                        hide-details
                        @change="item.saved = false"
                      />
                    </v-col>

                    <v-col cols="12" sm="5" v-if="item.signature_requirement">
                      <v-select
                        v-model="item.signature_status"
                        :items="signature_status_options"
                        :rules="[rule.required]"
                        label="Signature Status"
                        clearable
                        dense
                        hide-details
                        @change="item.saved = false"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text>
                  <p>Associations:</p>

                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        v-model="item.association.customers"
                        :items="customerNames"
                        :return-object="true"
                        item-text="name"
                        label="Customers"
                        chips
                        deletable-chips
                        small-chips
                        dense
                        hide-details
                        multiple
                        @change="item.saved = false"
                      />
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        v-model="item.association.properties"
                        :items="propertyAddresses"
                        :return-object="true"
                        item-text="address"
                        label="Properties"
                        chips
                        deletable-chips
                        small-chips
                        dense
                        hide-details
                        multiple
                        @change="item.saved = false"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text>
                  Visibility:

                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-checkbox
                        v-model="item.view_company"
                        label="Company"
                        dense
                        hide-details
                        @change="item.saved = false"
                      />
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-checkbox
                        v-model="item.view_customer"
                        label="Customer"
                        dense
                        hide-details
                        @change="item.saved = false"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="item.provided_by"
                        :items="provided_by_options"
                        :rules="[rule.required]"
                        label="Provided By"
                        clearable
                        dense
                        @change="item.saved = false"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider class="my-4" />

                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <!-- note list -->
                      <v-card
                        style="background: transparent"
                        ref="file_note_list"
                        flat
                      >
                        <p class="text-h6 font-weight-medium">
                          Notes
                          <v-icon> mdi-note-edit-outline </v-icon>
                        </p>
                        <p class="mb-0 grey--text font-weight-medium">
                          Click on the text field to input your notes, you can
                          also add screenshots or image files by copy/paste or
                          drag and drop into the text field below.
                        </p>

                        <v-card-text>
                          <p
                            v-if="item.notes.length === 0"
                            class="text-center grey--text text-subtitle-1"
                          >
                            No notes available. Make a note below.
                          </p>

                          <div
                            v-for="(note, index) in item.notes"
                            :key="note.id"
                            class="my-2"
                          >
                            <v-card class="rounded-lg">
                              <v-card-text>
                                <p class="d-flex align-center mb-0">
                                  <span>
                                    <v-icon class="text-body-1 mr-1">
                                      mdi-account-outline
                                    </v-icon>
                                    {{ note.user ? note.user.first_name : "" }}
                                    {{ note.user ? note.user.last_name : "" }}
                                  </span>

                                  <span class="ml-auto">
                                    <v-icon class="text-body-1 mr-1">
                                      mdi-calendar-outline
                                    </v-icon>
                                    {{ note.createdAt | formatDate }}
                                  </span>

                                  <span class="ml-5">
                                    <v-btn
                                      title="Click to edit note"
                                      @click="handle_edit_note(note, index)"
                                      icon
                                      small
                                    >
                                      <v-icon color="teal" class="text-body-1">
                                        mdi-pencil-outline
                                      </v-icon>
                                    </v-btn>

                                    <v-btn
                                      title="Click to delete"
                                      @click="handle_delete_note(index)"
                                      icon
                                      small
                                    >
                                      <v-icon color="red"> mdi-close </v-icon>
                                    </v-btn>
                                  </span>
                                </p>
                              </v-card-text>

                              <v-divider class="my-4" />

                              <v-card-text>
                                <div v-if="note.note" style="white-space: pre">
                                  {{ note.note }}
                                </div>

                                <div
                                  v-if="note.content"
                                  class="file-note-contents"
                                  v-html="note.content"
                                ></div>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-card-text>
                      </v-card>

                      <!-- note editor -->
                      <v-card
                        style="background: transparent"
                        ref="task_note_editor"
                        flat
                      >
                        <v-card-text class="pa-1">
                          <div>
                            <RichTextEditor
                              ref="fileTipTapEditor"
                              type="file_note"
                              :value_to_edit="temp_note"
                              @handleSaveNote="
                                temp_note_index = index;
                                handle_save_note($event);
                              "
                              @handleCancelEdit="
                                temp_note = undefined;
                                temp_note_index = null;
                              "
                            />
                          </div>
                        </v-card-text>
                      </v-card>

                      <v-dialog v-model="note_dialog" fullscreen>
                        <v-toolbar class="sticky-toolbar orange darken-3" flat>
                          <v-toolbar-title>
                            <v-icon>mdi-image</v-icon> Image Preview
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="note_dialog = false"
                            title="Close"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar>

                        <v-card>
                          <v-card-text>
                            <v-img :src="selectedImage" aspect-ratio="1" />
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions
                  class="grey darken-4"
                  style="position: sticky; bottom: 0"
                >
                  <v-btn
                    text
                    color="teal"
                    :disabled="item.saved"
                    @click="handleSaveFile(item, index)"
                    title="Click to save"
                  >
                    <v-icon class="mr-2 text-body-1">
                      mdi-content-save-outline
                    </v-icon>
                    Save
                  </v-btn>

                  <v-btn
                    text
                    color="red"
                    @click="deleteFile(index)"
                    title="Click to delete"
                  >
                    <v-icon class="mr-2 text-body-1">
                      mdi-trash-can-outline
                    </v-icon>
                    Delete
                  </v-btn>

                  <v-btn
                    text
                    @click="previewFile(item.type, item.dataUrl)"
                    color="grey"
                    :title="
                      isSplit ? 'Click to close preview' : 'Click to preview'
                    "
                  >
                    <v-icon v-if="isSplit" class="mr-2">mdi-eye-off</v-icon>
                    <v-icon v-else class="mr-2">mdi-eye</v-icon>
                    Preview
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>

    <div v-if="isDragging && !uploading && !uploadCompleted" class="drag-icon">
      <!-- Add your icon here -->
      <div class="d-flex align-center justify-center">
        <v-icon size="100" class="grey--text"> mdi-dropbox </v-icon>
        <p class="ml-3 text-h5 grey--text">Drop Files Here</p>
      </div>
    </div>

    <div v-if="uploading && !uploadCompleted">
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        "
      >
        <p class="text-h5 grey--text text-center mb-5">
          Please wait until upload is complete
        </p>

        <v-progress-circular
          :rotate="360"
          :size="100"
          :width="15"
          :value="progress"
          color="teal"
        >
          {{ progress }}%
        </v-progress-circular>
      </div>
    </div>

    <div v-if="uploadCompleted">
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        "
      >
        <v-icon class="mr-2 text-h1" color="green">
          mdi mdi-file-check-outline
        </v-icon>
        <p class="text-h5 grey--text text-center mb-5">
          {{ droppedFiles.length > 1 ? "Files" : "File" }} was uploaded
          successfully.
        </p>

        <v-btn @click="handleMoreUploads">
          More Uploads
        </v-btn>

        <v-btn
          class="ml-4"
          @click="
            uploadCompleted = false;
            $emit('show-files');
          "
        >
          See Uploads
        </v-btn>

        <v-btn
          color="red"
          class="ml-4"
          @click="
            uploadCompleted = false;
            $emit('close-upload-file-dialog');
          "
        >
          Close
        </v-btn>
      </div>
    </div>

    <div class="my-4" v-if="!uploading && !uploadCompleted">
      <v-btn
        v-if="droppedFiles.length && !isDragging"
        color="primary"
        :disabled="droppedFiles.some((file) => !file.saved)"
        class="ml-4"
        @click="uploadFiles"
      >
        <v-icon class="mr-2"> mdi-cloud-upload-outline </v-icon>
        Upload Files
      </v-btn>

      <!-- add more files input -->
      <v-btn class="ml-4" @click="browseFiles">Select Files</v-btn>

      <input
        type="file"
        ref="fileInput"
        @change="handleFiles"
        multiple
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import RichTextEditor from "@/components/note/TipTapEditor.vue";
import filt from "@/plugins/filters";
import API from "@/plugins/API";
import rule from "@/plugins/rule";

export default {
  props: {
    view_type: {
      type: String,
      default: "",
    },

    deal_id: {
      type: Number,
      default: null,
    },
  },

  components: {
    RichTextEditor,
  },

  data: () => ({
    loadingPage: false,
    error: false,
    errors: [],
    progress: 0,

    uploading: false,
    uploadCompleted: false,

    rule: rule,

    droppedFiles: [],

    isSplit: false,
    previewFileData: null,

    isDragging: false,
    dragCounter: 0, // Initialize the drag counter

    note_dialog: false,
    selectedImage: "",
    temp_note_index: null,
    temp_note: undefined,

    categories: [],
    category_file_type_ids: [],

    file_types: [],

    customers: [],

    properties: [],

    signature_status_options: ["Signed", "Unsigned", "Signed but incomplete"],

    status_options: ["Active", "Archived", "Draft"],

    provided_by_options: ["Internal", "Lender Provided", "Customer Provided"],
  }),

  methods: {
    handleMoreUploads() {
      this.uploadCompleted = false;
      this.droppedFiles = [];
    },

    handleSaveFile(item, i) {
      if (this.isSplit) {
        // validate the split form
        const valid = this.$refs.splitForm[i].validate();
        if (!valid) {
          alert("Please fill in all required fields.");
          return;
        }

        if (this.$refs.fileTipTapEditor[i].isTyping) {
          alert("Please save note first.");
          return;
        }
        item.saved = true;
        return;
      }

      // validate the non-split form
      const valid = this.$refs.notSplitForm[i].validate();
      if (!valid) {
        alert("Please fill in all required fields.");
        return;
      }

      console.log(this.$refs.fileTipTapEditor[i].isTyping);

      if (this.$refs.fileTipTapEditor[i].isTyping) {
        alert("Please save note first.");
        return;
      }
      item.saved = true;
    },

    handleFileCategoryChange(item) {
      if (!item.file_cat) return;
      const file_cat = this.categories.find((cat) => cat.id === item.file_cat);

      const file_type_ids = file_cat.file_types.map((type) => type.id);
      this.category_file_type_ids = [...file_type_ids];
    },

    handleFileTypeChange(item) {
      item.saved = false;
      if (!item.file_type) return;
      const file_type = this.file_types.find(
        (type) => type.id === item.file_type
      );

      item.proto_desc = file_type.description;
      item.signature_requirement = file_type.signature_requirement;
      item.view_company = file_type.view_company;
      item.view_customer = file_type.view_customer;
      item.provided_by = file_type.provided_by;
      item.association.customers = [];
      item.association.properties = [];

      if (file_type.customers)
        item.association.customers = [...this.customerNames];

      if (file_type.properties)
        item.association.properties = [...this.propertyAddresses];
    },

    // method for notes
    handle_save_note(value) {
      const { content, is_edit } = value;
      const index = this.temp_note_index;

      this.droppedFiles[index].saved = false;
      if (is_edit) {
        this.droppedFiles[index].notes[this.temp_note_index].content = content;
        this.temp_note_index = null;
      } else {
        this.droppedFiles[index].notes.push({
          note: "",
          content: content,
          user: {
            id: this.$store.getters["Auth/getAuthUser"].id,
            first_name: this.$store.getters["Auth/getAuthUser"].first_name,
            last_name: this.$store.getters["Auth/getAuthUser"].last_name,
          },
          createdAt: new Date().toISOString(),
        });
      }

      this.temp_note = undefined;
      this.temp_note_index = null;
    },

    handle_delete_note(item_index, index) {
      if (window.confirm("Are you sure you want to delete this note?")) {
        this.droppedFiles[item_index].saved = false;
        this.droppedFiles[item_index].notes.splice(index, 1);
      } else {
        return;
      }
    },

    handle_edit_note(note, index) {
      this.temp_note_index = index;
      this.temp_note = note;
    },

    onNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },

    previewFile(type, dataUrl) {
      if (this.isSplit) {
        this.isSplit = false;
        this.previewFileData = null;
        return;
      }

      this.isSplit = true;
      // Assuming `file` has a `type` attribute and a `dataUrl` for the content
      this.previewFileData = { type: type, dataUrl: dataUrl };
    },

    isImageFile(file) {
      return file.type.startsWith("image/");
    },

    isPdfFile(file) {
      return file.type === "application/pdf";
    },

    deleteFile(index) {
      if (window.confirm("Are you sure you want to delete this file?")) {
        this.droppedFiles.splice(index, 1);
      } else {
        return;
      }
    },

    getIcon(type) {
      if (type.includes("image")) {
        return "mdi-file-image-outline";
      } else {
        return "mdi-file-document-outline";
      }
    },

    getFileName(name) {
      return name.length > 20 ? name.substring(0, 20) + "..." : name;
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    handleDragLeave(event) {
      event.preventDefault();
      this.dragCounter--; // Decrement on drag leave
      if (this.dragCounter === 0) {
        this.isDragging = false; // Only set isDragging to false when fully left
      }
    },

    handleDragEnter(event) {
      event.preventDefault();
      this.dragCounter++; // Increment on drag enter
      if (this.dragCounter === 1) {
        // Only set isDragging to true on the first enter
        this.isDragging = true;
      }
    },

    browseFiles() {
      this.$refs.fileInput.click();
    },

    handleFiles(event) {
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.droppedFiles.push({
            id: Date.now(),
            file: file,
            description: "",
            name: file.name,
            size: file.size,
            signature_requirement: false,
            signature_status: null,
            status: "Active",
            archived_reason: "",
            type: file.type,
            view_company: false,
            view_customer: false,
            provided_by: "",
            file_type: null,
            file_cat: null,
            saved: false,
            previewing: false,
            dataUrl: e.target.result,

            association: {
              customers: [],
              properties: [],
            },

            notes: [],
          });
        };
        reader.readAsDataURL(file);
      });
    },

    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      this.dragCounter = 0; // Reset counter on drop

      const files = Array.from(event.dataTransfer.files);
      let new_index = null;

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.droppedFiles.push({
            id: Date.now(),
            file: file,
            description: "",
            name: file.name,
            size: file.size,
            signature_requirement: false,
            signature_status: null,
            status: "Active",
            archived_reason: "",
            type: file.type,
            view_company: false,
            view_customer: false,
            provided_by: "",
            file_type: null,
            file_cat: null,
            saved: false,
            previewing: false,
            dataUrl: e.target.result,

            association: {
              customers: [],
              properties: [],
            },

            notes: [],
          });

          new_index = this.droppedFiles.length - 1;

          setTimeout(() => {
            if (this.$refs.file_note_list[new_index]) {
              this.$refs.file_note_list[new_index].$el.addEventListener(
                "click",
                this.onNoteImageClick
              );
            }
          }, 1000);
        };

        reader.readAsDataURL(file);
      });
    },

    async uploadFiles() {
      try {
        this.uploading = true;

        console.log("droppedFiles: ", this.droppedFiles);

        let formData = new FormData();
        let filePropertiesArray = [];

        this.droppedFiles.forEach((fileItem, index) => {
          // Append file to formData
          formData.append(`file${index + 1}`, fileItem.file);

          // Extract file properties and add to array
          const { file, dataUrl, saved, previewing, ...rest } = fileItem;
          filePropertiesArray.push(rest);
        });

        // Append deal_id
        formData.append("deal_id", this.deal_id);

        // Convert file properties array to JSON string and append to formData
        formData.append("file_properties", JSON.stringify(filePropertiesArray));

        const res = await API().post(
          "api/internal-admin/deal/upload-files",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },

            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        );

        console.log("status: ", res.status);
        console.log("message: ", res.data.message);
        this.uploading = false;
        this.uploadCompleted = true;
      } catch (error) {
        console.error(error);
        this.uploading = false;
        this.error = true;
        this.errors.push({ message: error.response.data.message });
      }
    },

    async loadPageData() {
      try {
        const get_file_cat_res = await API().get(
          "api/internal-admin/file-cat/get-all-categories"
        );

        this.categories = get_file_cat_res.data.categories;

        const get_file_type_res = await API().get(
          "api/internal-admin/file-type/get-all-file-types"
        );

        this.file_types = get_file_type_res.data.file_types;

        const custs_and_props = await API().get(
          `api/internal-admin/deal/read-customers-and-properties?deal_id=${this.deal_id}`
        );

        this.customers = [...custs_and_props.data.customers];
        this.properties = [...custs_and_props.data.properties];
      } catch (error) {
        console.error(error);
        this.error = true;
        this.errors.push({ message: error.response.data.message });
      }
    },
  },

  computed: {
    filteredFileTypes() {
      if (this.category_file_type_ids.length === 0) return this.file_types;

      return this.file_types.filter((type) =>
        this.category_file_type_ids.includes(type.id)
      );
    },

    customerNames() {
      return this.customers.map((customer) => {
        return {
          id: customer.id,
          name: `${customer.first_name} ${customer.last_name}`,
        };
      });
    },

    propertyAddresses() {
      return this.properties.map((p) => {
        return {
          id: p.id,
          address: `${p.address.street_number} ${p.address.street_name}, ${p.address.city}, ${p.address.province} ${p.address.postal_code}`,
        };
      });
    },
  },

  mounted() {
    this.loadPageData();
  },

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss">
.main-content {
  height: 100vh;
  background-color: #111;
  overflow-x: hidden;
}

.drag-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.v-tab {
  justify-content: start;
}

.v-tab.v-tab {
  color: teal;
}

.split-container {
  display: flex;
  height: 75vh;
}

.non-split-container {
  display: flex;
  height: 75vh;
  overflow-y: auto;
}

.column {
  flex-grow: 1;
  overflow-x: hidden;
}

.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.file-note-contents {
  p {
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #444;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
